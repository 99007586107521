@import "~bootstrap/scss/bootstrap.scss";
@import "boxicons";

body {
  color: #404e69;
  background: #ffffff;
}

p {
  margin-bottom: 0;
}

a {
  color: #3967bf;
}

.card {
  border: 1px solid #ffffff;
  border-radius: 8px;
}

.card-body {
  padding: 24px;

  &.small {
    padding: 1rem 0.5rem;
  }
}

.form-control {
  color: #404e69;
  border: 1px solid #e4e8f1;
  border-radius: 5px;
}

.form-control::placeholder {
  /* Chrome, Firefox, Opera, Safari 10.1+ */
  font-weight: 400;
  font-size: 14px;
  line-height: 150%;
  color: #9e9e9e;
  opacity: 1; /* Firefox */
}

.form-control:-ms-input-placeholder {
  /* Internet Explorer 10-11 */
  font-weight: 400;
  font-size: 14px;
  line-height: 150%;
  color: #9e9e9e;
}

.form-control::-ms-input-placeholder {
  /* Microsoft Edge */
  font-weight: 400;
  font-size: 14px;
  line-height: 150%;
  color: #9e9e9e;
}

input.form-control:disabled,
.form-control[readonly] {
  background: #f5f5f5;
  font-weight: 400;
  font-size: 14px;
  line-height: 150%;
  color: #212121;
}

.previous {
  background: #43936c;
  margin-right: "10px";
  border-radius: "10px";
  padding: "0px 15px 0px 15px";
}

.btn.fl_btn,
.btn-light.fl_btn {
  border: 1px solid #e4e8f1;
  background-color: #ffffff;
  color: #404e69;
  font-size: 16px;
  font-weight: 500;
  &:hover,
  &:focus {
    background-color: #e4e8f1;
  }
  &.active {
    background-color: #e4e8f1;
  }
}

.btn.btn-outline-primary {
  color: #3967bf;
  border-color: #3967bf;
  &:hover {
    background-color: #3967bf;
    color: white;
  }
}

.btn-primary {
  background-color: #254e9c;
  border-color: #254e9c;
}

.section-1 {
  background-image: url(../images/bg-ikan.jpg);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;

  padding-top: 120px;
  padding-bottom: 60px;

  display: flex;
  flex-direction: column;
  align-items: center;

  .logo {
    margin-bottom: 34px;
  }

  .card-container {
    max-width: 1120px;

    .card-production-code {
      display: none;
      margin-left: 150px;
      margin-right: 150px;
      box-shadow: 0px 4px 8px 0px #0000001a;
      margin-bottom: 20px;

      .card-header-production-code {
        display: flex;
        justify-content: center;
        background-color: #ebb105;
        color: white;
        border-bottom: none;
      }

      .card-body-production-code {
        padding: 10px;
        text-align: center;

        .text {
          font-family: Inter, sans-serif;
          font-size: 18px;
          font-weight: 400;
          line-height: 27px;
          color: #212121;

          b {
            font-weight: 700;
          }
        }
      }
    }

    .table-production-code {
      display: block;
      overflow: hidden;
      border-radius: 8px;
      margin-left: 120px;
      margin-right: 120px;
      box-shadow: 0px 4px 8px 0px #0000001a;

      .table-header-production-code {
        background-color: #ebb105;
        color: white;
        border-bottom: none;

        .item-title {
          padding: 10px;
          text-align: center;
        }
      }

      .table-body-production-code {
        .item-data {
          font-family: Inter, sans-serif;
          font-size: 16px;
          font-weight: 400;
          line-height: 24px;
          color: #212121;
          text-align: center;
          padding: 10px;
        }
      }
    }

    .card-product-result {
      display: flex;
      justify-content: center;
      scroll-margin-top: 80px;
    }
  }
}

.section-2 {
  background-image: url(../images/phone-FLI.png);
  background-repeat: no-repeat;
  background-position: right;

  display: flex;
  flex-direction: row;
  justify-content: space-between;
  background-color: white;

  .text-container {
    width: 610px;
    padding-left: 110px;
    padding-top: 230px;
    padding-bottom: 390px;

    .text {
      font-size: 32px;
      font-weight: 700;
      color: #133c6b;
    }
  }

  .image-container {
    display: none;
    justify-content: end;

    .image {
      width: 600px;
    }
  }
}

.section-3 {
  padding: 100px 180px 100px 180px;
  background-color: #254e9c;

  .top-side {
    display: flex;
    flex-direction: row;
    justify-content: space-between;

    .text-container {
      width: 700px;
      margin-right: 40px;

      .header-text {
        font-size: 32px;
        font-weight: 700;
        line-height: 48px;
        margin-bottom: 24px;
        color: white;
      }

      .text {
        font-size: 24px;
        font-weight: 400;
        line-height: 36px;
        color: white;
      }
    }

    .image-container {
      display: flex;
      justify-content: end;
      height: max-content;
      margin-bottom: 45px;
    }
  }

  .verify-container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin-top: 120px;

    .verify-item {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;

      .img-background {
        background-color: white;
        width: 60px;
        height: 60px;
        border-radius: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
      }

      .sub-text {
        font-size: 20px;
        font-weight: 400;
        color: white;
        margin-bottom: 0;
      }

      .text {
        font-size: 48px;
        font-weight: 700;
        color: white;
        margin-bottom: 0;
      }
    }
  }
}

.section-4 {
  background-color: white;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 60px 180px 0px 0px;

  .right-side {
    .image-container {
      display: flex;
      justify-content: end;
      margin-bottom: 45px;
    }

    .text-container {
      width: 450px;

      .header-text {
        font-size: 32px;
        font-weight: 700;
        line-height: 48px;
        color: #254e9c;
        margin-bottom: 17px;
      }

      .item-list {
        display: flex;
        flex-direction: column;
        justify-content: left;

        .text-item {
          display: flex;
          margin-bottom: 24px;

          .subtitle {
            font-size: 20px;
            font-weight: 700;
            line-height: 30px;
            color: #254e9c;
            margin-bottom: 0;
          }

          .text {
            font-size: 16px;
            font-weight: 300;
            line-height: 24px;
            font-style: italic;
            color: #254e9c;
          }
        }
      }
    }
  }
}

.header-section {
  margin-bottom: 20px;

  .title {
    font-size: 26px;
    font-weight: 600;
    line-height: 39px;
  }

  .subtitle {
    font-size: 14px;
    font-weight: 400;
    line-height: 21px;
    color: #424242;

    .bold {
      font-weight: 700;
    }
  }
}

.card-product {
  display: flex;
  justify-content: center;
  border-radius: 8px;
  overflow: hidden;
  box-shadow: 0px 4px 8px 0px #0000001a;
  margin-top: 32px;

  .card-product-container {
    background: #ffffff;

    .card-product-header {
      display: flex;
      align-items: center;
      height: 70px;
      width: 100%;
      padding: 10px 15px;
      background: #fff;
      box-shadow: 0px 1px 0px 0px #dde0e5;
    }

    .card-product-body {
      padding: 15px;
      display: flex;
      flex-direction: column;
      // justify-content: center;
      // align-items: center;

      .title-head {
        margin-top: 8px;
        font-size: 14px;
        font-weight: 600;
        color: #424242;
        margin-left: 20px;
      }

      .title-head-mobile {
        margin-top: 8px;
        font-size: 14px;
        font-weight: 600;
        color: #424242;
      }

      .title {
        font-size: 14px;
        font-weight: 600;
        color: #424242;
      }

      .title .qc {
        margin-top: 50px;
      }

      .title-mobile {
        margin: 20px 0px 20px 0px;
        font-size: 14px;
        font-weight: 600;
        color: #424242;
      }

      .semi-title {
        font-size: 14px;
        font-weight: 600;
        color: #424242;
        margin-bottom: 12px;
      }

      .subtitle {
        font-size: 12px;
        font-weight: 400;
        color: #9e9e9e;
      }

      .little-text {
        font-size: 10px;
        font-weight: 500;
        color: #757575;
      }

      .text-1 {
        font-size: 12px;
        font-weight: 400;
        color: #424242;
      }

      .text-2 {
        font-size: 18px;
        font-weight: 700;
        color: #424242;
      }

      .text-4 {
        font-size: 12px;
        font-weight: 800;
        color: #424242;
      }

      .text-5 {
        font-size: 10px;
        font-weight: 400;
        color: #9e9e9e;
      }

      .text-6 {
        font-size: 12px;
        font-weight: 400;
        color: #9e9e9e;
      }

      .product-info {
        width: 100%;
        min-width: 832px;
      }

      .container-raw-material {
        width: 100%;
        max-height: 380px;
        overflow-y: auto;
        padding: 0px 10px;
      }

      .card-raw-material {
        border: 1px solid #eeeeee;
        border-radius: 8px;
        padding: 8px;
      }

      .seafood-journey {
        width: 100%;
        min-width: 832px;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: start;

        .card-port {
          width: 240px;
          border: 1px solid #eeeeee;
          border-radius: 8px;
          padding: 12px 16px;
          margin-right: 10px;
          cursor: pointer;

          &.active {
            border: 1px solid #254e9c;
            background-color: #f0f5ff;
          }
        }

        .card-port-mobile {
          border: 1px solid #eeeeee;
          border-radius: 8px;
          padding: 6px 10px;
          display: flex;
          flex-direction: column;
          text-align: center;
          height: 80px;
          cursor: pointer;

          &.active {
            border: 1px solid #254e9c;
            background-color: #f0f5ff;
          }

          img {
            width: 25px;
            margin: 5px 0px 5px 0px;
          }
        }

        .active-card {
          width: 100%;
          border: 1px solid #254e9c;
          background-color: #f0f5ff;
          padding: 10px;
          border-radius: 8px;
        }
      }

      .list-text {
        font-size: 12px;
        font-weight: 400;
        color: #424242;
        margin-bottom: 8px;

        .link {
          color: #3967bf;
          cursor: pointer;
          text-decoration: none;
        }
      }

      .icon {
        color: #254e9c;
        font-size: 20px;
      }

      .button-little-text {
        font-size: 10px;
        font-weight: 500;
      }

      .photo {
        justify-content: center;
        align-items: center;
        width: 100%;
        margin-bottom: 10px;

        .image {
          height: 180px;
          border-radius: 8px;
          border: 1px solid #eeeeee;
        }
      }

      .photo-parent {
        display: block flex;
        justify-content: center;
        align-items: center;
        width: 100%;
        height: 180px;

        .image {
          height: 180px;
          border-radius: 8px;
          border: 1px solid #eeeeee;
        }
      }

      .photo-child {
        justify-content: center;
        align-items: center;
        margin-top: 5px;

        .image {
          width: 60px;
          height: 60px;
          object-fit: cover;
          border-radius: 8px;
          border: 1px solid #eeeeee;
        }
      }

      .image-distributor {
        width: 70px;
        height: 70px;
        border: 1px solid #eeeeee;
        border-radius: 8px;
        height: auto;
        margin-bottom: 10px;
        padding: 8px;
        display: block;
        object-fit: contain;
      }

      @media (max-width: 560px) {
        background: #254e9c;
      }

      .image-certification {
        display: flex;
        flex-wrap: wrap;

        .image {
          margin-top: 10px;
          margin-right: 10px;
          padding: 5px;
          border: 1px solid #eeeeee;
          border-radius: 8px;
          height: 60px;
          width: 60px;
          object-fit: contain;
          margin-bottom: 20px;
        }
      }

      .map {
        width: 100%;
        height: 100%;
        border-radius: 8px;
        overflow: hidden;
      }

      .card-border {
        border: 1px solid #eeeeee;
      }
    }
  }
}

.label-section {
  font-weight: 600;
  font-size: 20px;
  color: #9e9e9e;
}

.table-fix {
  width: 100%;
  overflow: auto;
}

.fl_table {
  color: #404e69;
  > :not(:first-child) {
    border-top: unset !important;
  }

  thead tr th,
  tbody tr td {
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    padding: 10px;
  }

  thead {
    tr {
      th {
        vertical-align: middle;
      }
    }
  }
  tbody {
    tr th,
    tr td {
      font-weight: 400;
      font-size: 16px;
      color: #424242;
    }

    .text-blue {
      color: #3967bf;
    }
  }
}

.text-group {
  margin-bottom: 8px;

  .text-3 {
    font-size: 16px;
    font-weight: 600;
    color: #212121;
  }

  .text-blue {
    font-size: 16px;
    font-weight: 400;
    color: #3967bf;
    text-decoration: none;
  }
}

.badge {
  width: fit-content;
  display: flex;
  justify-content: center;
  align-items: center;

  padding: 6px 10px;
  background: #cfdfff;
  color: #3967bf;
  border-radius: 4px;
  border: 1px solid #aecaff;

  font-size: 12px;
  font-weight: 500;
}

.badge-yes {
  width: fit-content;
  display: flex;
  justify-content: center;
  align-items: center;

  padding: 6px;
  background: #baffdd;
  color: #43936c;
  border-radius: 8px;

  font-size: 12px;
  font-weight: 400;
}

@media (max-width: 1020px) {
  .section-1 {
    padding: 120px 16px 80px 16px;

    .card-container {
      width: inherit;
    }
  }

  .section-4 {
    flex-direction: column-reverse;
    padding: 0px;

    .image-phone {
      width: 440px;
    }

    .right-side {
      padding: 60px 20px 0px 30px;

      .image-container {
        justify-content: center;
      }

      .text-container {
        width: inherit;

        .header-text {
          font-size: 24px;
          font-weight: 700;
          line-height: 32px;
          margin-bottom: 20px;
        }
      }
    }
  }
}

@media (max-width: 980px) {
  .card-product {
    .card-product-container {
      .card-product-body {
        align-items: start;
        background: #fafafa;

        .card {
          border: 1px solid #eeeeee;
        }

        .product-info {
          width: 100%;
          min-width: 100%;
        }

        .seafood-journey {
          width: 100%;
          min-width: 100%;
          display: flex;
          flex-direction: column;

          .card-port-container {
            width: fit-content;
          }

          .card-port {
            width: 100%;
          }

          .active-card {
            width: "100%";
            border: "1px solid #254e9c";
            background-color: "#f0f5ff";
            padding: "10px";
            border-radius: "8px";
          }
        }
      }
    }
  }
}

@media (max-width: 800px) {
  .section-1 {
    .card-container {
      .card-production-code {
        display: block;
        margin-left: 80px;
        margin-right: 80px;
      }

      .table-production-code {
        display: none;
      }
    }
  }

  .section-2 {
    flex-direction: column;
    background-image: none;

    .text-container {
      width: inherit;
      padding-left: 40px;
      padding-top: 40px;
      padding-bottom: 40px;

      .text {
        font-size: 32px;
        font-weight: 700;
        color: #133c6b;
      }
    }

    .image-container {
      display: flex;
      .image {
        width: 580px;
      }
    }
  }

  .section-3 {
    padding: 40px 20px;

    .top-side {
      flex-direction: column-reverse;

      .text-container {
        width: inherit;
      }

      .image-container {
        justify-content: center;
      }
    }

    .verify-container {
      flex-direction: column;
      align-items: center;
      margin-top: 20px;

      .verify-item {
        margin-top: 60px;
      }
    }
  }
}

@media (max-width: 580px) {
  .section-1 {
    padding: 120px 16px 80px 16px;

    .card-container {
      .card-production-code {
        margin-left: 20px;
        margin-right: 20px;
      }
    }
  }

  .section-2 {
    flex-direction: column;

    .text-container {
      width: inherit;
      margin: 40px 20px;
      padding-left: 0px;
      padding-top: 0px;
      padding-bottom: 0px;

      .text {
        font-size: 16px;
        font-weight: 700;
        color: #133c6b;
      }
    }

    .image-container {
      .image {
        width: 375px;
      }
    }
  }

  .section-4 {
    flex-direction: column-reverse;
    padding: 0px;

    .image-phone {
      width: 340px;
    }
  }
}

.mobile-active-card {
  width: "100%";
  border: "1px solid #254e9c";
  background-color: "#f0f5ff";
  padding: "10px";
  border-radius: "8px";
}
